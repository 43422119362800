import Vue from 'vue'
import Vuex from 'vuex'
import AuthModule from './auth-login'

Vue.use(Vuex)

var userPermissions = localStorage.getItem("userData_permissions")
Vue.mixin({
  methods: {
    can: (key) => userPermissions.includes(key)
  }
})

export default new Vuex.Store({
  //namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    AuthModule
  }
})
