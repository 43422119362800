<template>
  <div>
    <spinner :start="StartLoding" />
    <div class="stylecontainer">
      <v-row style="">
        <v-col style="" md="2" cols="6" sm="4">
          <span
            style="
              color: #3c5cacff;
              text-align: center !important;
              font-size: 30px !important;
              font-weight: 500;
            "
          >
            Users
          </span>
        </v-col>
        <v-col md="4" class="hideincategory" cols="0" sm="0"></v-col>
        <v-col md="4" cols="12" sm="6">
          <span>
            <v-text-field
              class="stylingiconsearch"
              v-model="showallwithfiltering.subString"
              label="Search"
              prepend-inner-icon="mdi-magnify"
              style="border-radius: 12px; text-align: center"
              single-line
              solo
              @change="getall()"
            >
            </v-text-field
          ></span>
        </v-col>
        <v-col md="2" cols="12" sm="6" style="width: 100% !important">
          <v-dialog v-model="dialog" class="" max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="btncategoryinmediaquery"
                v-bind="attrs"
                v-on="on"
                style="
                  width: 100% !important;
                  border-radius: 12px !important;
                  background-color: #3c5cacff !important;
                  color: #fff5f5 !important;
                  height: 40px !important;
                  font-size: 14px !important;
                  float: right;
                "
              >
                Add New User
              </v-btn>
            </template>
            <v-card style="border-radius: 40px !important">
              <v-card-title class="">
                <v-row>
                  <v-col md="3"></v-col>
                  <v-col class="pcoloryellowmanageuser mt-5" md="6">
                    <span class="text-h5">Create User</span>
                  </v-col>
                  <v-col md="3"></v-col>
                </v-row>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <label class="">Full Name</label>
                    <v-row>
                      <v-col md="6">
                        <v-text-field
                          :rules="nameRule"
                          v-model="user.firstName"
                          class="textfieldborderradius"
                          label="First Name"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col md="6">
                        <v-text-field
                          :rules="nameRule"
                          class="textfieldborderradius"
                          v-model="user.lastName"
                          label="Last Name"
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <label class="">UserName</label>
                    <v-text-field
                      :rules="nameRule"
                      class="textfieldborderradius"
                      v-model="user.userName"
                      label=""
                      outlined
                    ></v-text-field>
                    <label class="">Role</label>
                    <v-select
                      class="textfieldborderradius"
                      :items="Roles"
                      v-model="user.role"
                      :item-value="'id'"
                      :item-text="'name'"
                      outlined
                    ></v-select>

                    <label class="">Phone</label>
                    <v-text-field
                      :rules="nameRule"
                      class="textfieldborderradius"
                      v-model="user.phoneNumber"
                      label=""
                      outlined
                    ></v-text-field>

                    <label class="">Email</label>
                    <v-text-field
                      required
                      :rules="emailRules"
                      class="textfieldborderradius"
                      v-model="user.email"
                      label=""
                      outlined
                    ></v-text-field>

                    <label class="">Password</label>
                    <v-text-field
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="PasswordRules"
                      :type="show1 ? 'text' : 'password'"
                      name="input-10-1"
                      label="Password"
                      hint="At least 8 characters"
                      counter
                      @click:append="show1 = !show1"
                      class="textfieldborderradius"
                      v-model="user.password"
                      required
                      outlined
                    ></v-text-field>

                    <label class="">Confirm Password</label>
                    <v-text-field
                      :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show2 ? 'text' : 'password'"
                      name="input-10-1"
                      label="Confirm Password"
                      counter
                      @click:append="show2 = !show2"
                      class="textfieldborderradius"
                      :rules="confirmPasswordRules"
                      required
                      v-model="user.confirmPassword"
                      outlined
                    ></v-text-field>

                    <v-btn
                      @click="CreateUser"
                      style="
                        width: 100% !important;
                        border-radius: 12px !important;
                        background-color: #3c5cacff !important;
                        color: #fff5f5 !important;
                        height: 40px !important;
                        font-size: 16px !important;
                      "
                    >
                      Create
                    </v-btn>
                  </v-form>
                </v-container>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>

      <!-- =============== data table ====== -->

      <v-dialog v-model="DeleteDialog" max-width="400px">
        <v-card style="border-radius: 12px !important">
          <v-card-text>
            <v-container>
              <v-form lazy-validation>
                <v-row>
                  <v-col
                    class="mt-7"
                    cols="12"
                    sm="12"
                    md="12"
                    style="text-align: center"
                  >
                    <span style="text-align: center">
                      Are you sure you want to delete this User?
                    </span>
                  </v-col>
                </v-row>
                <br />
                <br />
                <v-row>
                  <v-col>
                    <v-btn
                      @click="cancel()"
                      style="
                        width: 100% !important;
                        border-radius: 12px !important;
                        background-color: #ffffff !important;
                        color: #000000 !important;
                        height: 40px !important;
                        font-size: 16px !important;
                      "
                    >
                      Cancel
                    </v-btn></v-col
                  >
                  <v-col>
                    <v-btn
                      @click="Delete()"
                      style="
                        width: 100% !important;
                        border-radius: 12px !important;
                        background-color: #ff3235 !important;
                        color: #fff5f5 !important;
                        height: 40px !important;
                        font-size: 16px !important;
                      "
                    >
                      Delete
                    </v-btn></v-col
                  >
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-data-table
        style="font-size: larger !important"
        class="stylingdatatable elevation-3"
        :search="showallwithfiltering.subString"
        :items-per-page="showallwithfiltering.pageSize"
        :headers="headers"
        :items="usersmanaginglist"
        hide-default-footer
      >
        <template v-slot:footer>
          <v-row style="padding-top: 2%">
            <v-col md="3"> </v-col>
            <v-col md="1">
              <v-select
                :items="sizeOptions"
                v-model="showallwithfiltering.pageSize"
                :item-value="'id'"
                :item-text="'name'"
                @change="changePageSize()"
                solo
              ></v-select>
            </v-col>
            <v-col md="5">
              <v-pagination
                v-model="showallwithfiltering.pageNumber"
                :length="totalPages"
                @input="getall()"
                total-visible="6"
              ></v-pagination>
            </v-col>
            <v-col md="3"> </v-col>
          </v-row>
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import spinner from "../../components/Spinner.vue";
import { dataService } from "@/services/data-service";
export default {
  components: { spinner },
  data() {
    return {
      DeleteDialog: false,
      StartLoding: false,
      valid: true,
      show1: false,
      show2: false,
      dialog: false,
      nameRule: [
        (v) => !!v || "Required",
        (v) => v.length >= 3 || "min 3 characters ",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      confirmPasswordRules: [
        (v) =>
          v === this.user.password ||
          "The password confirmation does not match.",
      ],
      PasswordRules: [
        (v) => !!v || "Password is required",
        (v) =>
          /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(v) ||
          "Password must have 8 character with at least one special character, upper and lower case letters and a number",
      ],

      TargetId: 0,

      totalPages: 0,
      Roles: [
        {
          id: 1,
          name: "Admin",
        },
        {
          id: 2,
          name: "User",
        },
      ],
      sizeOptions: [
        {
          id: 1,
          name: 1,
        },
        {
          id: 10,
          name: 10,
        },
        {
          id: 20,
          name: 20,
        },
        {
          id: 30,
          name: 30,
        },
        {
          id: 50,
          name: 50,
        },
        {
          id: 100,
          name: 100,
        },
      ],
      showallwithfiltering: {
        pageNumber: 1,
        pageSize: 10,
        subString: "",
      },
      user: {
        role: 2,

        phoneNumber: "",
        firstName: "",
        lastName: "",
        userName: "",
        email: "",
        password: "",
      },
      headers: [
        { text: "First Name", value: "firstName" },
        { text: "last Name", value: "lastName" },
        { text: "Email", value: "email" },
        { text: "User Name", value: "userName" },
        { text: "Role", value: "role" },
        { text: "Phone Number", value: "phoneNumber" },

        { text: "Badge Serial", value: "nfc" },
      ],
      usersmanaginglist: [],
    };
  },
  methods: {
    changePageSize() {
      this.showallwithfiltering.pageNumber = 1;
      this.getall();
    },
    DeleteUser(item) {
      //  this.StartLoding = true;
      this.DeleteDialog = true;
      this.TargetId = item.id;
    },
    Delete() {
      this.DeleteDialog = false;
      // console.log(this.TargetId)
      dataService.delete(
        "Authentication/DeleteUser",
        { id: this.TargetId },
        () => {
          this.StartLoding = false;
          this.getall();
        },
        () => {
          this.StartLoding = false;
        }
      );
    },
    cancel() {
      this.DeleteDialog = false;
    },
    getall() {
      this.StartLoding = true;
      dataService.getAllByPagination(
        "Authentication/GetAllUsers",
        this.showallwithfiltering,
        (response) => {
          this.usersmanaginglist = response.data.data.items;
          this.totalPages = response.data.data.totalPages;
          this.StartLoding = false;
        },
        () => {
          this.StartLoding = false;
        }
      );
    },
    CreateUser() {
      if (this.$refs.form.validate()) {
        this.StartLoding = true;
        dataService.createItem(
          "Authentication/CreateUser",
          this.user,
          (response) => {
            // console.log(response.data, "creating")
            this.dialog = false;
            this.StartLoding = false;
            this.getall();
          },
          () => {
            this.StartLoding = false;
          }
        );
      }
    },
  },

  mounted() {
    this.getall();
  },
};
</script>
