import http from "../http"
import Vue from 'vue';
import VueNoty from 'vuejs-noty';
import 'vuejs-noty/dist/vuejs-noty.css';
Vue.use(VueNoty);
import router from '@/router';
export const dataService = {
    getAll(endPoint, callback_success,callback_fail) {
      
        http.get(endPoint).then(response => {
            
            if(response.data.succeeded){
                callback_success(response.data)
            }
            else    {
                Vue.noty.error(response.data.errors);
                callback_fail();
            }
          

        })
        .catch(er => {
           
         
            if (er.response.status == 400) {
                
                Vue.noty.error(er.response.data);
                callback_fail();
              }
              if (er.response.status == 401) {
                this.$store.dispatch("AuthModule/logout");
                callback_fail();
                router.push("/Login")
    
              }
              if (er.response.status == 404) {
                callback_fail();
                router.push("/NotFound");
              }
        });
    },
    getAllByPagination(endPoint, data, callback_success, callback_fail) {
       
        http.post(endPoint, JSON.stringify(data)).then(response => {
          
            if(response.data.succeeded){
                callback_success(response)
            }
            else    {
                Vue.noty.error(response.data.errors);
                callback_fail();
                
            }
        }).catch(er => {
           
         
            if (er.response.status == 400) {
                
                Vue.noty.error(er.response.data);
                callback_fail();
              }
              if (er.response.status == 401) {
                this.$store.dispatch("AuthModule/logout");
                callback_fail();
                router.push("/Login")
    
              }
              if (er.response.status == 404) {
                callback_fail();
                router.push("/NotFound");
              }
        });
    },
    createItem(endPoint, data, callback_success, callback_fail) {
        http.post(endPoint, JSON.stringify(data)).then(response => {
           
            if(response.data.succeeded){
                callback_success(response)
            }
            else    {
                Vue.noty.error(response.data.errors);
                callback_fail();
                
            }
        }).catch(er => {
           
         
            if (er.response.status == 400) {
                
                Vue.noty.error(er.response.data);
                callback_fail();
              }
              if (er.response.status == 401) {
                this.$store.dispatch("AuthModule/logout");
                callback_fail();
                router.push("/Login")
    
              }
              if (er.response.status == 404) {
                callback_fail();
                router.push("/NotFound");
              }
        });
    },
   
    update(endPoint, data, callback_success, callback_fail) {
        http.post(endPoint, JSON.stringify(data)).then(response => {

         
            if(response.data.succeeded){
                callback_success(response)
            }
            else    {
                Vue.noty.error(response.data.errors);
                callback_fail();
                
            }
        }).catch(er => {
           
         
            if (er.response.status == 400) {
                
                Vue.noty.error(er.response.data);
                callback_fail();
              }
              if (er.response.status == 401) {
                this.$store.dispatch("AuthModule/logout");
                callback_fail();
                router.push("/Login")
    
              }
              if (er.response.status == 404) {
                callback_fail();
                router.push("/NotFound");
              }
        });
    },
    delete(endPoint, data, callback_success,callback_fail) {
        http.post(endPoint, JSON.stringify(data)).then(response => {
          
            if(response.data.succeeded){
                callback_success(response)
            }
            else    {
               
                Vue.noty.error(response.data.errors);
                callback_fail();
                
            }
        }).catch(er => {
           
         
            if (er.response.status == 400) {
                
                Vue.noty.error(er.response.data);
                callback_fail();
              }
              if (er.response.status == 401) {
                this.$store.dispatch("AuthModule/logout");
                callback_fail();
                router.push("/Login")
    
              }
              if (er.response.status == 404) {
                callback_fail();
                router.push("/NotFound");
              }
        });
    },
   

}