<template>
  <div>
    <spinner :start="StartLoding" />
    <div class="stylecontainer">
      <h3
        style="color: #3c5cacff; font-size: 30px !important; font-weight: 500"
      >
        licenseKey
      </h3>
      <br />

      <div>
        <v-row>
          <v-col md="12" col="6">
            <v-text-field v-model="licenseKey" label="" outlined>
              <template v-slot:append>
                <v-icon
                  @click="copyLicenseKey(licenseKey)"
                  :class="{ 'copied-icon': isCopyClicked }"
                  >mdi-content-copy</v-icon
                >
                <v-tooltip bottom v-model="showTooltip" :open-on-hover="false">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on"></v-icon>
                  </template>
                  Copied
                </v-tooltip>
              </template>
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-btn class="btnaddnewsonghome" @click="Update()"> Update </v-btn>
        </v-row>
      </div>
    </div>
  </div>
</template>
<script>
import spinner from "../../components/Spinner.vue";
import { dataService } from "@/services/data-service";
export default {
  components: { spinner },
  data() {
    return {
      StartLoding: false,
      licenseKey: "",
      showTooltip: false,
      isCopyClicked: false,
    };
  },
  methods: {
    Update() {
      (this.StartLoding = true),
        dataService.createItem(
          "Authentication/UpdateLicenseKey",
          { licenseKey: this.licenseKey },
          () => {
            this.StartLoding = false;
            this.isCopyClicked = false;
          },
          () => {
            this.StartLoding = false;
          }
        );
    },
    getProfileData() {
      (this.StartLoding = true),
        dataService.createItem(
          "Authentication/GetLicenseKey",
          {},
          (response) => {
            this.licenseKey = response.data.data.licenseKey;
            // console.log(this.licenseKey);
            this.StartLoding = false;
          },
          () => {
            this.StartLoding = false;
          }
        );
    },

    copyLicenseKey(licenseKey) {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        // Use Clipboard API if available
        navigator.clipboard
          .writeText(licenseKey)
          .then(() => {
            this.showTooltip = true;
          })
          .catch((error) => {
            console.error("Failed to copy license key:", error);
          });
      } else {
        const textArea = document.createElement("textarea");
        textArea.value = licenseKey;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);
        this.showTooltip = true;
      }
      setTimeout(() => {
        this.showTooltip = false; // Hide the tooltip after a brief delay
      }, 2000);
      //   this.licenseKey = ""
      this.isCopyClicked = true;
    },
  },
  mounted() {
    this.getProfileData();
  },
};
</script>
<style>
.changedesigncamera.theme--light.v-icon {
  color: #ffffff !important;
  border-radius: 12px !important;
  background-color: #3e3e4d;
  border: 0px #2725254f solid !important;
  height: 40px;
  width: 40px;
}
.copied-icon {
  color: #3c5cac !important;
}
</style>
