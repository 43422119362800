<template>
    <div style="">
        <spinner :start="StartLoding"/>
        <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
                <v-col style="background-color: #3C5CACFF;text-align: center;min-height: 101.6vh;" md="4" cols="12"
                    sm="12">
                    <p class="pupdatepass">Update Password</p>


                    <div style="padding-left:10%;padding-right: 10%;">
                        <p class="ptextpassword" style="color: white !important;">New Password</p>

                        <v-text-field style="margin-bottom: 3%;border-radius: 12px;"
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :rules="PasswordRules"
                            :type="show1 ? 'text' : 'password'" name="input-10-1" label="Password"
                            hint="At least 8 characters" counter @click:append="show1 = !show1" dense
                            v-model="resetPasswordData.password" single-line solo required></v-text-field>

                        <p class="ptextpassword" style="color: white !important;">Confirm New Password</p>

                        <v-text-field style="margin-bottom: 3%;border-radius: 12px;"
                            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'" :type="show2 ? 'text' : 'password'"
                            name="input-10-1" label="Confirm Password" counter @click:append="show2 = !show2" dense
                            :rules="confirmPasswordRules" v-model="resetPasswordData.confirmPassword" single-line solo
                            required></v-text-field>
                    </div>
                    <v-btn @click="reset()" class="btnconfirm" style="color:#3C5CACFF !important;">Confirm</v-btn>
                </v-col>
                <v-col class="hideimageinlogin" style="min-height: 10.5vh;" md="8" cols="0" sm="0">
                    <img style="" contain transition="scale-transition" width="100%"
                        src="../../assets/images/headerimg/backGroung.png" />
                </v-col>
            </v-row>
        </v-form>


    </div>
</template>
<script>
import router from '@/router';
import spinner from "../../components/Spinner.vue"
import { dataService } from '@/services/data-service';
export default {
    data() {
        return {
            StartLoding:false,
            valid: true,
            show1: false,
            show2: false,

            confirmPasswordRules: [

                v => v === this.resetPasswordData.password || 'The password confirmation does not match.'
            ],
            PasswordRules: [
                v => !!v || 'Password is required',
                v => /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(v) || 'Password must have 8 character with at least one special character, upper and lower case letters and a number',
            ],
            resetPasswordData: {
                token: this.$route.query.token,
                email: this.$route.query.email,
                password: "",
                confirmPassword: ""
            }

        }
    },

    methods: {
        reset() {
            if  (this.$refs.form.validate()){
                this.StartLoding=true;

            dataService.createItem("Authentication/ResetPassword", this.resetPasswordData
                , (response) => {
                    this.StartLoding=false;
                  
                    router.push("/")
                },()=>{

                    this.StartLoding=false;
                }
            )

            }
        },

    },
   
}
</script>
<style  scoped >
.theme--light.v-icon {
    color: #fafafa !important;

}
</style>