import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@mdi/font/css/materialdesignicons.css'
import './http'
import moment from 'moment'
import "./index.css";

import excel from 'vue-excel-export'

Vue.use(excel)

Vue.prototype.moment = moment
//======== style ====
import '@/assets/style/index.css'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
