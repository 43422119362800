<template>
    <div class="notchangeColor">
        <spinner :start="StartLoding" />
        <div class="stylecontainer">
            <h3 style="color: #707070;">Profile</h3>
            <br />

            <div >


                <label class="pcoloryellowprofile">Full Name</label>
                <v-row>
                    <v-col md="6" col="6">
                        <v-text-field v-model="ProfileData.firstName" disabled label="First Name" outlined>
                        </v-text-field>
                    </v-col>
                    <v-col md="6" col="6">
                        <v-text-field v-model="ProfileData.lastName" disabled label="Last Name" outlined></v-text-field>
                    </v-col>
                </v-row>

                <label class="pcoloryellowprofile">UserName</label>
                <v-text-field v-model="ProfileData.userName" disabled label="UserName" outlined></v-text-field>

                <label class="pcoloryellowprofile">Email</label>
                <v-text-field v-model="ProfileData.email" disabled label="Email" outlined></v-text-field>

            </div>

        </div>
    </div>

</template>
<script>
import spinner from "../../components/Spinner.vue"
import { dataService } from '@/services/data-service';
export default {
    components: { spinner },
    data() {
        return {
            StartLoding: false,
            ProfileData: {
                firstName: "",
                userName: "",
                lastName: "",
                email: ""
            }

        }
    },
    methods: {
        getProfileData() {
            this.StartLoding = true,
                dataService.getAll("Authentication/GetCurrentUser",
                    (response) => {
                        this.ProfileData = response.data;
                        this.StartLoding = false;

                    }, () => {
                        this.StartLoding = false;
                    }
                )

        }
    },
    mounted() {
        this.getProfileData();
    }
}
</script>
<style>
.changedesigncamera.theme--light.v-icon {
    color: #FFFFFF !important;
    border-radius: 12px !important;
    background-color: #3E3E4D;
    border: 0px #2725254f solid !important;
    height: 40px;
    width: 40px;
}
.notchangeColor .theme--light.v-input input, .theme--light.v-input textarea{
    color: #3E3E4D!important;
}
</style>