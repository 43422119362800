import Vue from "vue";
import router from "@/router";
import auth from "@/services/auth-service";

import VueNoty from 'vuejs-noty';
import 'vuejs-noty/dist/vuejs-noty.css';
Vue.use(VueNoty);

const sucessLoginSignUp = (commit, data) => {
    //debugger; // eslint-disable-line no-debugger
    const bearerToken = "Bearer " + `${data.token}`;
    //console.log(bearerToken, "bearer token")

    Vue.prototype.$http.defaults.headers.common[
        "Authorization"
    ] = bearerToken;
    commit("SET_AUTH_USER", data);
    commit("SET_TOKEN", data.token);
    commit("SET_ROLE", data.roles[0]);
    commit("SET_USER_EMAIL", data.email);
    commit("SET_USER_DATA", data);
    // localStorage.setItem("userType", data.userId);
    router.push("/");

    router.go(0);
};

export default {
    namespaced: true,
    state: {
        isLoggining: false,
        status: "",
        token: localStorage.getItem("Admin_token") || "",
        user: JSON.parse(localStorage.getItem("userData_user")) || {
            data: {
                roles: ""
            }
        },
        // role: localStorage.getItem("userType") || "",
        roles: localStorage.getItem("SET_ROLE") || "",
        userEmail: localStorage.getItem("userData_email") || "",

    },
    getters: {
        isLoggedIn: state => !!state.token,
        userData: state => state.user,
        userRole: state => state.roles[0],
        userEmail: state => state.userEmail
    },
    mutations: {
        SET_TOKEN(state, token) {
            //debugger; // eslint-disable-line no-debugger
            state.token = token
        },
        SET_AUTH_USER(state, user) {
            //debugger; // eslint-disable-line no-debugger
            state.user = user
        },
        SET_ROLE(state, role) {
            //debugger; // eslint-disable-line no-debugger
            state.roles = role
        },
        SET_USER_DATA(data) {
           // debugger; // eslint-disable-line no-debugger
            localStorage.setItem("Admin_token", data.token);
            localStorage.setItem("userData_user", JSON.stringify(data));
            localStorage.setItem("userData_email", data.userEmail);
           // localStorage.setItem("userData_permissions", JSON.stringify(data.user.data.groupIds));
        },
        SET_USER_EMAIL(state,email) {
            state.userEmail=email
            //debugger; // eslint-disable-line no-debugger
        },
        REMOVE_USER_DATA(state) {
            //debugger; // eslint-disable-line no-debugger
           // console.log(state,"state data in remove")
            localStorage.removeItem("Admin_token");
            localStorage.removeItem("userData_user");
            // localStorage.removeItem("userType");
            localStorage.removeItem("SET_ROLE");

            state.token = ''
            state.user = ''
            state.roles = ''
            delete Vue.prototype.$http.defaults.headers.common["Authorization"];
        }

    },
    actions: {
        signIn({ commit }, userData) {
           // debugger; // eslint-disable-line no-debugger
            return new Promise((resolve, reject) => {
                auth
                    .login(userData)
                    .then((resp) => {
                        debugger; // eslint-disable-line no-debugger
                        if (resp.data.succeeded == true) {
                            sucessLoginSignUp(commit, resp.data.data);
                            debugger; // eslint-disable-line no-debugger
                            router.push("/");
                            resolve(resp);
                            //console.log(resp, "userdata in auth login store")
                        }
                        else{
                            Vue.noty.error(resp.data.errors);
                            commit("REMOVE_USER_DATA");
                      
                            return reject(resp.data.errors);
                        }
                      
                      
                    })
                    .catch((err) => {
                        commit("REMOVE_USER_DATA");
                        //console.log("message error remove token")
                        return reject(err);
                    });
            });
        },
        //========== logout =========
        logout({ commit }) {
            //debugger; // eslint-disable-line no-debugger
            commit("REMOVE_USER_DATA");
            router.go(0);
            // router.push("/login");

            delete Vue.prototype.$http.defaults.headers.common["Authorization"];

        },
    },



}
