<template>
  <div v-if="series">
    <apexchart
      type="bar"
      height="350"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  props: ["categories", "series", "totalHours"],
  components: { apexchart: VueApexCharts },
  data() {
    return {
      series: this.series,
      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              total: {
                enabled: true,
                offsetX: 20,
                style: {
                  fontSize: "15px",
                  fontWeight: 700,
                  color: "#3C5CAC",
                },
                formatter: (val, opt) => {
                  return this.getTotalHours(val, opt);
                },
              },
            },
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        title: {
          text: " ",
        },
        xaxis: {
          categories: this.categories,
        },
        yaxis: {
          title: {
            text: undefined,
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " days";
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 0,
        },
      },
    };
  },
  mounted() {},

  methods: {
    getTotalHours(val, opt) {
      var shownData = 0;
      if (val != undefined) {
        if (this.series.length == 1) {
          shownData = this.totalHours[opt.dataPointIndex];
        } else {
          shownData = val + (val == 0 || val == 1 ? " day" : " days");
        }
      }

      return shownData;
    },
  },
};
</script>

<style lang="scss" scoped></style>
