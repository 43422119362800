<template>
    <div style="">
        <spinner :start="StartLoding"/>
        <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
            <v-col style="background-color: #3C5CACFF;text-align: center;min-height: 101.6vh;" md="4" cols="12" sm="12">
                <p class="pforgetpass">Forget Password</p>
                <p class="pmessageverified" style="color: white !important;">Enter a verified email to be able to <br />
                    reset your password.
                </p>
               
                <div style="padding-left:10%;padding-right: 10%;">
                    <p class="ptextvaildemail" style="color: white !important;" >Valid Email Address</p>
                    <v-text-field v-model="ForgetPassword.email"
                    :rules="emailRules"
                    required
                    dense style="margin-bottom: 3%;border-radius: 12px !important;" label="Enter your email" single-line solo></v-text-field>
                </div>

                <!-- <router-link style="text-decoration: none;" class-active="active" to="/UpdatePassword" exact> -->
                     <v-btn class="btnsendemail" style="color: #3C5CACFF !important;" @click="ForgetPasswordMethod()">send email</v-btn>
                    <!-- </router-link> -->

               
            </v-col>
            <v-col  class="hideimageinlogin"  style="min-height: 10.5vh;" md="8" cols="0" sm="0">
                <img style="" contain transition="scale-transition" width="100%"
                    src="../../assets/images/headerimg/backGroung.png" />
            </v-col>
        </v-row>
    </v-form>

    </div>
</template>


<script>
import spinner from "../../components/Spinner.vue"
import router from '@/router';
import { dataService } from '@/services/data-service';
export default {
    components:{spinner},
    data() {
        return {
            StartLoding:false,
            valid: true,
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
            ForgetPassword: {
                email: "",
             
            }
        }
    },
   
    methods: {
        ForgetPasswordMethod() {

            if  (this.$refs.form.validate()){
                this.StartLoding=true;
            dataService.createItem("Authentication/ForgetPassword", this.ForgetPassword
                , () => {
                    this.StartLoding=false;
                    router.push("/checkMail")
                },
                ()=>{
                    this.StartLoding=false;

                }
            )
    
         
        }
    },

    },
    mounted() {

    }
}
</script>
