<template>
  <div style="" class="clocking">
    <spinner :start="StartLoding" />

    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <V-col></V-col>
        <v-col
          v-if="isClocking"
          style="
            background-color: #3c5cacff;
            text-align: center;
            min-height: 101.5vh;
          "
          md="4"
          cols="12"
          sm="12"
        >
          <br />
          <div class="w-full d-flex justify-center">
            <img
              class="mb-7"
              contain
              transition="scale-transition"
              width="250"
              style=""
              src="../../assets/images/loginimg/clockingIcon.png"
            />
          </div>
          <div style="padding-right: 8%; padding-left: 8%">
            <p class="pColor">Email Address</p>
            <v-text-field
              :rules="emailRules"
              v-model="logindata.email"
              dense
              class="textfieldsty"
              outlined
              required
            ></v-text-field>
          </div>

          <div style="padding-right: 8%; padding-left: 8%">
            <p class="pColor">Password</p>
            <v-text-field
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              counter
              @click:append="show1 = !show1"
              :rules="PasswordRules"
              v-model="logindata.password"
              dense
              class="textfieldsty"
              outlined
              required
            ></v-text-field>
          </div>
          <v-btn @click="login" class="btnlogin"> clock </v-btn><br />
          <br />
          <v-row>
            <v-col md="1" cols="1" sm="1"></v-col>
            <v-col md="5" cols="5" sm="5">
              <span> </span>
            </v-col>
            <v-col class="" style="padding-top: 32px" md="5" cols="5" sm="5">
              <router-link
                class="pcolorredforgetpass"
                class-active="active"
                to="/ForgetPassword"
                exact
                >Forgot Password?</router-link
              >
            </v-col>
            <v-col md="0" cols="1" sm="1"></v-col>
          </v-row>
        </v-col>
        <v-col
          v-else
          style="
            background-color: transparent;
            padding-top: 2%;
            text-align: center;
            min-height: 101.5vh;
          "
          md="5"
          cols="12"
          sm="12"
        >
          <span
            style="font-size: xx-large; font-weight: 600; color: #3c5cacff"
            >{{ responseData.fullName }}</span
          >

          <span
            style="font-size: xx-large; font-weight: 600; color: #3c5cacff"
            v-if="responseData.isClockingIn"
          >
            {{ cockingInMessage }}
          </span>

          <span
            style="font-size: xx-large; font-weight: 600; color: #3c5cacff"
            v-else
          >
            {{ cockingoutMessage }}
          </span>

          <V-col>
            <img
              style=""
              contain
              transition="scale-transition"
              width="100%"
              src="../../assets/images/headerimg/Confirmed attendance.png"
            />
          </V-col>
        </v-col>

        <V-col> </V-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import spinner from "../../components/Spinner.vue";
import { dataService } from "@/services/data-service";
export default {
  components: { spinner },
  data() {
    return {
      cockingInMessage: "you have successfully clocked in",
      cockingoutMessage: "you have successfully clocked out",
      isClocking: true,
      responseData: {},
      StartLoding: false,
      show1: false,
      valid: true,
      logindata: {
        email: "",
        password: "",
        location: "",
        oneTimeToken: this.$router.currentRoute.params.token,
      },
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      PasswordRules: [(v) => !!v || "Password is required"],
    };
  },
  mounted() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.showPosition);
    } else {
      // console.log("Geolocation is not supported by this browser.");
    }
  },
  methods: {
    showPosition(position) {
      this.logindata.location =
        position.coords.latitude + "," + position.coords.longitude;
    },
    login() {
      // console.log(this.logindata);

      if (this.$refs.form.validate()) {
        this.StartLoding = true;
        dataService.createItem(
          "Kiosks/AddFrom_Web",
          this.logindata,
          (response) => {
            this.isClocking = false;
            this.responseData = response.data.data;
            // console.log(this.responseData);
            this.StartLoding = false;
          },
          () => {
            this.StartLoding = false;
          }
        );
      }
    },
  },
};
</script>

<style>
.clocking .theme--light.v-input input,
.clocking .theme--light.v-input textarea {
  color: rgb(255 255 255) !important;
}
.clocking
  .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(
    .v-input--has-state
  )
  > .v-input__control
  > .v-input__slot
  fieldset {
  color: #fff;
}

@media all and (min-width: 768px) and (max-width: 959px) {
  .hideimageinlogin {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
    opacity: 0 !important;
    visibility: collapse !important;
  }
}

/* #Mobile (Landscape) Note: Design for a width of 600px */
@media all and (min-width: 600px) and (max-width: 767px) {
  .hideimageinlogin {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
    opacity: 0 !important;
    visibility: collapse !important;
  }
}

/* ============== Mobile =====================*/
@media all and (min-width: 480px) and (max-width: 599px) {
  .hideimageinlogin {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
    opacity: 0 !important;
    visibility: collapse !important;
  }
}

/*  #Mobile (Portrait) Note: Design for a width of 320px */
@media all and (max-width: 479px) {
  .hideimageinlogin {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
    opacity: 0 !important;
    visibility: collapse !important;
  }
}
</style>
