import axios from "axios";
import Vue from "vue";

//const $domain = 'https://localhost:7184/api/'

const $domain ='https://attend.s-badge.com/api/'
const token = localStorage.getItem('Admin_token')
const base = axios.create({
    baseURL: $domain,
    headers: !token ? {
        "Accept": "multipart/form-data",
        "Content-Type": "application/json",
    } : {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + token
    }
})



Vue.prototype.$http = base

Vue.prototype.$domain = $domain
export default base