<template>
  <div class="loginFont login-page" style="">
    <spinner :start="StartLoding" />

    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col
          style="
            background-color: #3c5cacff;
            text-align: center;
            min-height: 101.5vh;
          "
          md="4"
          cols="12"
          sm="12"
        >
          <!-- <img class="mt-9 mb-0" contain transition="scale-transition" width="200"
                        style=" margin-bottom: -31px !important;"
                        src="../../assets/images/loginimg/Coptic Melodia.png" /> -->
          <br />
          <div class="w-full d-flex justify-center">
            <img
            class="mb-7"
            contain
            transition="scale-transition"
            width="250"
            style=""
            src="../../assets/images/loginimg/clockingIcon.png"
          />
            <!-- <article class="clock">
              <div class="hours-container">
                <div class="hours"></div>
              </div>
              <div class="minutes-container">
                <div class="minutes"></div>
              </div>
              <div class="seconds-container">
                <div class="seconds"></div>
              </div>
            </article> -->
          </div>
          <div style="padding-right: 8%; padding-left: 8%; color: #fff">
            <p class="pColor">Email Address</p>
            <v-text-field
              style="color: #fff"
              :rules="emailRules"
              v-model="logindata.email"
              dense
              class="textfieldsty"
              outlined
              required
            ></v-text-field>
          </div>

          <div style="padding-right: 8%; padding-left: 8%; color: #fff">
            <p class="pColor">Password</p>
            <v-text-field
              style="color: #fff"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              counter
              @click:append="show1 = !show1"
              @keyup.enter="login"
              :rules="PasswordRules"
              v-model="logindata.password"
              dense
              class="textfieldsty"
              outlined
              required
            ></v-text-field>
          </div>
          <v-btn @click="login" class="btnlogin"> Login </v-btn><br />
          <br />
          <v-row>
            <v-col md="1" cols="1" sm="1"></v-col>
            <v-col md="5" cols="5" sm="5">
              <span> </span>
            </v-col>
            <v-col class="" style="padding-top: 32px" md="5" cols="5" sm="5">
              <router-link
                class="pcolorredforgetpass"
                class-active="active"
                to="/ForgetPassword"
                exact
                >Forgot Password?</router-link
              >
            </v-col>
            <v-col md="0" cols="1" sm="1"></v-col>
          </v-row>
        </v-col>
        <v-col
          class="hideimageinlogin"
          style="min-height: 10.5vh"
          md="8"
          cols="0"
          sm="0"
        >
          <img
            style=""
            contain
            transition="scale-transition"
            width="100%"
            src="../../assets/images/headerimg/backGroung.png"
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import spinner from "../../components/Spinner.vue";
export default {
  components: { spinner },
  data() {
    return {
      StartLoding: false,
      show1: false,
      valid: true,
      logindata: {
        email: "",
        password: "",
      },
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      PasswordRules: [(v) => !!v || "Password is required"],
    };
  },
  mounted() {
    this.initLocalClocks();
    // this.setUpMinuteHands();
    // this.moveMinuteHands();
    // this.moveSecondHands();
  },
  methods: {
    login() {
      if (this.$refs.form.validate()) {
        this.StartLoding = true;

        const email = this.logindata.email;
        const password = this.logindata.password;
        this.$store
          .dispatch("AuthModule/signIn", {
            email,
            password,
          })
          .catch((err) => {
            if (err) {
              this.invalidData = err.message;
              this.StartLoding = false;
            }
          });
      }
    },
    initLocalClocks() {
      // Get the local time using JS
      var date = new Date();
      var seconds = date.getSeconds();
      var minutes = date.getMinutes();
      var hours = date.getHours();

      // Create an object with each hand and it's angle in degrees
      var hands = [
        {
          hand: "hours",
          angle: hours * 30 + minutes / 2,
        },
        {
          hand: "minutes",
          angle: minutes * 6,
        },
        {
          hand: "seconds",
          angle: seconds * 6,
        },
      ];
      // Loop through each of these hands to set their angle
      for (var j = 0; j < hands.length; j++) {
        var elements = document.querySelectorAll("." + hands[j].hand);
        for (var k = 0; k < elements.length; k++) {
          elements[k].style.webkitTransform =
            "rotateZ(" + hands[j].angle + "deg)";
          elements[k].style.transform = "rotateZ(" + hands[j].angle + "deg)";
          // If this is a minute hand, note the seconds position (to calculate minute position later)
          if (hands[j].hand === "minutes") {
            elements[k].parentNode.setAttribute(
              "data-second-angle",
              hands[j + 1].angle
            );
          }
        }
      }
    },
    setUpMinuteHands() {
      // Find out how far into the minute we are
      var containers = document.querySelectorAll(".minutes-container");
      var secondAngle = containers[0].getAttribute("data-second-angle");
      if (secondAngle > 0) {
        // Set a timeout until the end of the current minute, to move the hand
        var delay = ((360 - secondAngle) / 6 + 0.1) * 1000;
        setTimeout(function () {
          moveMinuteHands(containers);
        }, delay);
      }
    },

    /*
     * Do the first minute's rotation
     */
    moveMinuteHands(containers) {
      for (var i = 0; i < containers.length; i++) {
        containers[i].style.webkitTransform = "rotateZ(6deg)";
        containers[i].style.transform = "rotateZ(6deg)";
      }
      // Then continue with a 60 second interval
      setInterval(function () {
        for (var i = 0; i < containers.length; i++) {
          if (containers[i].angle === undefined) {
            containers[i].angle = 12;
          } else {
            containers[i].angle += 6;
          }
          containers[i].style.webkitTransform =
            "rotateZ(" + containers[i].angle + "deg)";
          containers[i].style.transform =
            "rotateZ(" + containers[i].angle + "deg)";
        }
      }, 60000);
    },
    moveSecondHands() {
      var containers = document.querySelectorAll(".seconds-container");
      setInterval(function () {
        for (var i = 0; i < containers.length; i++) {
          if (containers[i].angle === undefined) {
            containers[i].angle = 6;
          } else {
            containers[i].angle += 6;
          }
          containers[i].style.webkitTransform =
            "rotateZ(" + containers[i].angle + "deg)";
          containers[i].style.transform =
            "rotateZ(" + containers[i].angle + "deg)";
        }
      }, 1000);
    },
  },
};
</script>

<style>
/* input {
  color: #fff !important;
} */

/* Start clocking style */

.clock {
  border-radius: 50%;
  background: transparent url(../../assets/images/loginimg/ios_clock.svg)
    no-repeat center;
  background-size: 70%;
  height: 20em;
  padding-bottom: 31%;
  position: relative;
  width: 20em;
}

.clock:after {
  background: #fff;
  border-radius: 50%;
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 5%;
  height: 5%;
  z-index: 10;
}
.minutes-container,
.hours-container,
.seconds-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.hours {
  background: #fff;
  height: 20%;
  left: 48.75%;
  position: absolute;
  top: 30%;
  transform-origin: 50% 100%;
  width: 2.5%;
}
.minutes {
  background: #fff;
  height: 25%;
  left: 48%;
  position: absolute;
  top: 25%;
  transform-origin: 50% 100%;
  width: 2%;
}

.seconds {
  background: #fff;
  height: 30%;
  left: 49.5%;
  position: absolute;
  top: 27%;
  transform-origin: 50% 80%;
  width: 1%;
  z-index: 8;
}
@keyframes rotate {
  100% {
    transform: rotateZ(360deg);
  }
}
.hours-container {
  animation: rotate 43200s infinite linear;
}
.minutes-container {
  animation: rotate 3600s infinite linear;
}
.seconds-container {
  animation: rotate 60s infinite linear;
}
.minutes-container {
  animation: rotate 3600s infinite steps(60);
}
.seconds-container {
  animation: rotate 60s infinite steps(60);
}
.minutes-container {
  transition: transform 0.3s cubic-bezier(0.4, 2.08, 0.55, 0.44);
}
.seconds-container {
  transition: transform 0.2s cubic-bezier(0.4, 2.08, 0.55, 0.44);
}
/* End clocking style */
.login-page .v-text-field--outlined >>> fieldset {
  border-color: white;
  color: white;
}
.login-page .theme--light.v-input input,
.login-page .theme--light.v-input textarea {
  color: rgb(255 255 255) !important;
}
.login-page .theme--light.v-label {
  /*color: #00000063 !important;*/
  font-family: Arial, sans-serif !important;
  font-size: 15px;
}

.login-page .theme--light.v-icon {
  /* color: #3c5cacff !important; */
  border-radius: 6px !important;
  /* border: 1px #2725254f solid; */
}

.login-page
  .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(
    .v-input--has-state
  )
  > .v-input__control
  > .v-input__slot
  fieldset {
  color: #ede7e733 !important;
}

@media all and (min-width: 768px) and (max-width: 959px) {
  .login-page .hideimageinlogin {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
    opacity: 0 !important;
    visibility: collapse !important;
  }
}

/* #Mobile (Landscape) Note: Design for a width of 600px */
@media all and (min-width: 600px) and (max-width: 767px) {
  .login-page .hideimageinlogin {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
    opacity: 0 !important;
    visibility: collapse !important;
  }
}

/* ============== Mobile =====================*/
@media all and (min-width: 480px) and (max-width: 599px) {
  .login-page .hideimageinlogin {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
    opacity: 0 !important;
    visibility: collapse !important;
  }
}

/*  #Mobile (Portrait) Note: Design for a width of 320px */
@media all and (max-width: 479px) {
  .login-page .hideimageinlogin {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
    opacity: 0 !important;
    visibility: collapse !important;
  }
}
</style>
