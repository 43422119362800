<template>
  <div class="notchangeColor">
    <spinner :start="StartLoding" />
    <div class="divforall">
      <v-row :class="{ 'justify-space-between': reportStatus }">
        <v-col md="4" sm="6">
          <div class="d-flex align-start">
            <span
              style="
                color: #3c5cacff;
                text-align: center !important;
                font-size: 30px !important;
                font-weight: 500;
              "
              class="mr-7"
            >
              Attendance Report
            </span>
            <div class="toggle-report">
              <v-switch
                v-model="reportStatus"
                :label="reportStatus == 0 ? 'Table' : 'Chart'"
                v-bind="attrs"
                v-on="on"
              />
            </div>
          </div>
        </v-col>

        <v-col md="3" sm="6" justify="end">
          <v-menu
            ref="menu2"
            v-model="menuStartDate"
            :close-on-content-click="false"
            :return-value.sync="SDFrom"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Date"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                hint="MM/DD/YYYY format"
                v-model="dateFormatted"
                style="border-radius: 12px; text-align: center"
                single-line
                solo
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="rangeDate"
              range
              no-title
              scrollable
              @change="changeRange"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="()=>{menuStartDate = false}">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="$refs.menu2.save(SDFrom)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col md="3" sm="6" v-if="!reportStatus">
          <v-text-field
            v-model="showallAttendancewithfiltering.subString"
            label="Search"
            prepend-inner-icon="mdi-magnify"
            style="border-radius: 12px; text-align: center"
            single-line
            solo
            @input="getAll()"
            @change="getAll()"
          >
          </v-text-field>
        </v-col>
        <v-col md="2" sm="6" class="mt-3" v-if="!reportStatus">
          <a @click="exportData" class="btn-creation wo-diff-style">
            <v-icon class="colorIcon">mdi-export</v-icon>
            Export File
          </a>
        </v-col>
      </v-row>

      <!-- ============= Div Table ======= -->
      <v-expand-transition>
        <div class="home-table" v-show="!reportStatus">
          <v-data-table
            item-key="email"
            style="font-size: larger !important"
            hide-default-footer
            :headers="headers"
            show-expand
            :single-expand="singleExpand"
            :items="transformedAttendance"
            :expanded.sync="expanded"
          >
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="7">
                <v-expansion-panels>
                  <v-expansion-panel
                    v-for="(yearData, year) in organizedData(
                      item.corporateAttendance
                    )"
                    :key="year"
                  >
                    <v-expansion-panel-header>{{
                      year
                    }}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-expansion-panels>
                        <v-expansion-panel
                          v-for="(monthData, month) in yearData"
                          :key="month"
                        >
                          <v-expansion-panel-header>{{
                            month
                          }}</v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-data-table
                              class="px-15 py-0"
                              :server-items-length="monthData.length"
                              :headers="AttendanceHeaders"
                              :items="monthData"
                              item-key="email"
                              hide-default-footer
                            >
                              <template v-slot:header.clockingInDate="{ text }">
                                <div
                                  class="d-flex items-center text-sm header-toggle"
                                >
                                  <span class="mt-6 mr-2">{{
                                    showClockingInDate ? "Military" : "AM/PM"
                                  }}</span>

                                  <v-switch
                                    v-model="showClockingInDate"
                                    label="clockingIn Date"
                                    v-bind="attrs"
                                    v-on="on"
                                  />
                                </div>
                              </template>
                              <template v-slot:item="{ item }">
                                <tr
                                  :style="{
                                    'border-top':
                                      calculateDayOfWeek(
                                        item.clockingInDate
                                      ) === 'Sun'
                                        ? '2px solid #3c5cac70'
                                        : 'none',
                                  }"
                                >
                                  <td>
                                    {{
                                      calculateDayOfWeek(item.clockingInDate)
                                    }}
                                    <span class="mx-[2px] text-gray-200"
                                      >|</span
                                    >

                                    {{
                                      formatDate(
                                        convertUTCDateToLocalDate(
                                          new Date(item.clockingInDate)
                                        ).toLocaleString()
                                      )
                                    }}
                                  </td>
                                  <td>
                                    <span v-if="item.clockInLocationLatLong">
                                      <v-tooltip bottom>
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-btn
                                            icon
                                            dark
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="openDialog(index)"
                                            class="ml-7 sm:ml-0 md:ml-0"
                                          >
                                            <v-icon
                                              style="color: red !important"
                                              >mdi-google-maps</v-icon
                                            >
                                          </v-btn>
                                        </template>
                                        <span>
                                          <iframe
                                            id="map-iframe"
                                            :src="
                                              generateMapUrl(
                                                item.clockInLocationLatLong
                                              )
                                            "
                                            height="300"
                                            width="400"
                                            style="border: 0"
                                            allowfullscreen=""
                                          ></iframe
                                        ></span>
                                      </v-tooltip>

                                      <v-dialog
                                        v-model="dialogClockIn[index]"
                                        fullscreen
                                        hide-overlay
                                        transition="dialog-bottom-transition"
                                      >
                                        <v-card>
                                          <v-toolbar dark color="#3C5CAC">
                                            <v-btn
                                              icon
                                              dark
                                              @click="closeDialog(index)"
                                            >
                                              <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                          </v-toolbar>
                                          <v-list three-line subheader>
                                            <v-list-item>
                                              <v-list-item-content>
                                                <iframe
                                                  id="map-iframe"
                                                  :src="
                                                    generateMapUrl(
                                                      item.clockInLocationLatLong
                                                    )
                                                  "
                                                  height="580"
                                                  style="border: 0"
                                                  allowfullscreen=""
                                                ></iframe>
                                              </v-list-item-content>
                                            </v-list-item>
                                          </v-list>
                                        </v-card>
                                      </v-dialog>
                                    </span>
                                  </td>

                                  <td>
                                    <span v-if="item.clockOutLocationLatLong">
                                      <v-tooltip bottom>
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-btn
                                            icon
                                            dark
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="openDialogOut(index)"
                                            class="ml-7 sm:ml-0 md:ml-0"
                                          >
                                            <v-icon
                                              style="color: red !important"
                                              >mdi-google-maps</v-icon
                                            >
                                          </v-btn>
                                        </template>
                                        <span>
                                          <iframe
                                            id="map-iframe"
                                            :src="
                                              generateMapUrl(
                                                item.clockOutLocationLatLong
                                              )
                                            "
                                            height="300"
                                            width="400"
                                            style="border: 0"
                                            allowfullscreen=""
                                          ></iframe
                                        ></span>
                                      </v-tooltip>

                                      <v-dialog
                                        v-model="dialogClockOut[index]"
                                        fullscreen
                                        hide-overlay
                                        transition="dialog-bottom-transition"
                                      >
                                        <v-card>
                                          <v-toolbar dark color="#3C5CAC">
                                            <v-btn
                                              icon
                                              dark
                                              @click="closeDialogOut(index)"
                                            >
                                              <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                          </v-toolbar>
                                          <v-list three-line subheader>
                                            <v-list-item>
                                              <v-list-item-content>
                                                <iframe
                                                  id="map-iframe"
                                                  :src="
                                                    generateMapUrl(
                                                      item.clockOutLocationLatLong
                                                    )
                                                  "
                                                  height="580"
                                                  style="border: 0"
                                                  allowfullscreen=""
                                                ></iframe>
                                              </v-list-item-content>
                                            </v-list-item>
                                          </v-list>
                                        </v-card>
                                      </v-dialog>
                                    </span>
                                  </td>

                                  <td>
                                    <span v-if="item.clockingOutDate != null">
                                      {{
                                        calculateDayOfWeek(item.clockingOutDate)
                                      }}
                                      <span class="mx-[2px] text-gray-200"
                                        >|</span
                                      >
                                      {{
                                        formatDate(
                                          convertUTCDateToLocalDate(
                                            new Date(item.clockingOutDate)
                                          ).toLocaleString()
                                        )
                                      }}
                                    </span>
                                    <span v-else>-- missing --</span>
                                  </td>

                                  <td>
                                    {{
                                      convertMinutesToHoursAndMinutes(
                                        item.totalAttendInMin
                                      )
                                    }}
                                  </td>
                                </tr>
                              </template>

                            </v-data-table>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

              </td>
            </template>
          </v-data-table>
        </div>
      </v-expand-transition>
      <!-- Chart Report -->
      <div v-if="reportStatus" class="chart-container">
        <div v-if="categories.length > 0 && Attendance && series.length > 0">
          <chart :categories="categories" :series="series" :totalHours="totalHours"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import spinner from "../../components/Spinner.vue";
import { excelParser } from "@/excel-parser";
import moment from "moment";
import { dataService } from "@/services/data-service";
//import { response } from 'express';
import chart from "./chart.vue";
export default {
  components: { spinner, chart },
  data() {
    return {
      reportStatus: 0,
      panelOpen: true,
      ExportData: [],
      SDFrom: null,
      SDTo: null,
      menuStartDate: false,
      menuEndDate: false,
      dateFormatted: null,
      rangeDate: null,
      dateFormattedEnd: null,
      StartLoding: false,
      expanded: [],
      singleExpand: true,
      AttendanceHeaders: [
        { value: "clockingInDate", text: "clockingIn Date", sortable: false },
        { value: "clockingOutDate", text: "clockingOut Date", sortable: false },
        {
          value: "clockInLocationLatLong",
          text: "clockIn Location",
          sortable: false,
        },

        {
          value: "clockOutLocationLatLong",
          text: "clockOut Location",
          sortable: false,
        },
        { value: "totalAttendInMin", text: "Attend HR", sortable: false },
      ],
      headers: [
        { text: "", value: "data-table-expand" },
        { value: "firstName", text: "First Name" },
        { value: "lastName", text: "Last Name" },
        { value: "email", text: "Email" },
        { value: "role", text: "Role" },
        { value: "totalMin", text: "Total in HR" },
      ],
      Attendance: [],

      //========== filtering =========
      showallAttendancewithfiltering: {
        subString: "",
      },
      totalPages: 0,
      sizeOptions: [
        {
          id: 1,
          name: 1,
        },
        {
          id: 10,
          name: 10,
        },
        {
          id: 20,
          name: 20,
        },
        {
          id: 30,
          name: 30,
        },
        {
          id: 50,
          name: 50,
        },
        {
          id: 100,
          name: 100,
        },
      ],
      // dialog setting
      dialogClockIn: Array(),
      dialogClockOut: Array(),
      notifications: false,
      sound: true,
      widgets: false,
      showClockingInDate: false,
      categories: [],
      series: [],
      filteredAttendance: [],
      firstDay: "",
      lastDay: "",
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      totalHours: [],
    };
  },
  watch: {
    SDFrom() {
      this.dateFormatted = this.formatDate2(this.SDFrom);
    },
    SDTo() {
      this.dateFormattedEnd = this.formatDate2(this.SDTo);
    },
  },
  computed: {
    transformedAttendance() {
      return this.Attendance.map((item) => {
        return {
          ...item,
          totalMin: this.convertMinutesToHoursAndMinutes(item.totalMin),
        };
      });
    },
    organizedData() {
      return (attendanceData) => {
        const organizedData = {};

        attendanceData.forEach((attendance) => {
          const date = new Date(attendance.clockingInDate);
          const year = date.getFullYear();
          const month = date.toLocaleString("en-us", { month: "long" });

          if (!organizedData[year]) {
            organizedData[year] = {};
          }

          if (!organizedData[year][month]) {
            organizedData[year][month] = [];
          }

          organizedData[year][month].push(attendance);
        });

        return organizedData;
      };
    },
  },
  mounted() {
    this.getAll();
  },
  methods: {
    changeRange(e) {
      if (e[1]) {
        this.dateFormatted = `${e[0]} to ${e[1]}`;
        this.getAttendanceByDate();
        this.menuStartDate = false;
      }
    },
    openDialog(index) {
      this.$set(this.dialogClockIn, index, true);
    },
    closeDialog(index) {
      this.$set(this.dialogClockIn, index, false);
    },
    openDialogOut(index) {
      this.$set(this.dialogClockOut, index, true);
    },
    closeDialogOut(index) {
      this.$set(this.dialogClockOut, index, false);
    },
    convertMinutesToHoursAndMinutes(totalMinutes) {
      const totalHours = Math.floor(totalMinutes / 60);

      const remainingMinutes = totalMinutes % 60;

      // const roundedMinutes = Math.round(remainingMinutes / 15) * 15;
      return (
        (totalHours < 10 ? `0${totalHours}` : totalHours) +
        ":" +
        (remainingMinutes < 10 ? `0${remainingMinutes}` : remainingMinutes) +
        (totalHours < 1 ? " min" : " hr")
      );
    },

    generateMapUrl(latLong) {
      const baseUrl = "https://maps.google.com/maps";
      const queryParams = {
        q: `${latLong?.split(",")[0]},${latLong?.split(",")[1]}`,
        t: "",
        z: 13,
        ie: "UTF8",
        iwloc: "",
        output: "embed",
      };
      const queryString = Object.entries(queryParams)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join("&");

      return `${baseUrl}?${queryString}`;
    },
    exportData() {
      debugger; // eslint-disable-line no-debugger
      let sampleData = this.ExportData;
      sampleData = sampleData.map((item) => {
        // Create a copy of the item
        const newItem = { ...item };

        // Replace totalAttendInMin with totalAttend
        newItem.totalAttend = this.convertMinutesToHoursAndMinutes(
          item.totalAttendInMin
        );

        // Optionally, you can omit totalAttendInMin if you don't want it in the exported data
        delete newItem.totalAttendInMin;

        return newItem;
      });

      var currentdate = new Date();
      var fileName =
        "Report " +
        currentdate.getDate() +
        "/" +
        (currentdate.getMonth() + 1) +
        "/" +
        currentdate.getFullYear();

      excelParser().exportDataFromJSON(sampleData, fileName, null);
    },
    getAttendanceByDate() {
      this.showallAttendancewithfiltering.clockInFilter = {
        clockInFrom: this.rangeDate[0] + "T00:00:00Z",
        clockInTo: this.rangeDate[1] + "T00:00:00Z",
      };
      this.categories = [];
      this.totalHours = [];
      this.getAll();
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    formatDate2(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    formatDate(value) {
      var finalClocking = null;
      if (this.showClockingInDate) {
        finalClocking = moment(value).format("MM/DD/YYYY | HH:mm ");
      } else {
        finalClocking = moment(value).format("MM/DD/YYYY | hh:mm a ");
      }
      return finalClocking;
    },
    calculateDayOfWeek(date) {
      const parsedDate = new Date(date);
      const dayOfWeek = parsedDate.toLocaleDateString("en-US", {
        weekday: "short",
      });
      return dayOfWeek;
    },
    convertUTCDateToLocalDate(date) {
      var newDate = new Date(
        date.getTime() + date.getTimezoneOffset() * 60 * 1000
      );

      var offset = date.getTimezoneOffset() / 60;
      var hours = date.getHours();

      newDate.setHours(hours - offset);

      return newDate;
    },
    openGoogleMap(item) {
      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + item,
        "_blank"
      );
    },
    //============ get all Attendance ======
    getAll() {
      this.StartLoding = true;
      try {
        dataService.getAllByPagination(
          "Authentication/GetAllUsersAttendanceQuery",
          this.showallAttendancewithfiltering,
          (response) => {
            this.Attendance = [];
            this.Attendance = response.data.data;
            this.filteredData(response.data.data);
            this.ExportData = [];
            this.Attendance.forEach((element) => {
              element.corporateAttendance.forEach((element2) => {
                this.ExportData.push(element2);
              });
            });

            this.StartLoding = false;
          },
          () => {
            this.StartLoding = false;
          }
        );
      } catch (error) {
      } finally {
      }
    },

    //////////////////////////////////// for chart
    getMonthName(dateString) {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const date = new Date(dateString);
      return monthNames[date.getMonth()];
    },

    filteredData(allData) {
      // this.categories = [];
      var startDateTime = "";
      var endDateTime = "";
      if (this.rangeDate) {
        startDateTime = new Date(this.rangeDate[0]);
        endDateTime = new Date(this.rangeDate[1]);
      } else {
        this.calculateFirstAndLastDayOfCurrentMonth();
        startDateTime = new Date(this.firstDay);
        endDateTime = new Date(this.lastDay);
      }
      const res = allData.map((data) => {
        return data.corporateAttendance.filter((entry) => {
          const entryDate = new Date(entry.clockingInDate.split("T")[0]);
          return entryDate >= startDateTime && entryDate <= endDateTime;
        });
      });
      this.filteredAttendance = res;
      const result = [];
      const processedUsers = new Set();
      res.forEach((user) => {
        if (user.length !== 0) {
          user.forEach((entry) => {
            const monthName = this.getMonthName(entry.clockingInDate);
            const isUserProcessed = processedUsers.has(entry.email + monthName);
            if (
              !this.categories.includes(entry.firstName + " " + entry.lastName)
            ) {
              this.categories.push(entry.firstName + " " + entry.lastName);
            }
            if (!isUserProcessed) {
              processedUsers.add(entry.email + monthName);

              const existingMonth = result.find(
                (item) => item.name === monthName
              );
              const hours = user.reduce(
                (acc, curr) => acc + curr.totalAttendInMin,
                0
              );
              this.totalHours.push(this.convertMinutesToHoursAndMinutes(hours));
              if (existingMonth) {
                existingMonth.data.push(
                  user.filter(
                    (item) =>
                      this.getMonthName(item.clockingInDate) == monthName
                  ).length
                );
              } else {
                result.push({
                  name: monthName,
                  data: [
                    user.filter(
                      (item) =>
                        this.getMonthName(item.clockingInDate) == monthName
                    ).length,
                  ],
                });
              }
            }
          });
        }
      });
      this.series = result;
    },
    calculateFirstAndLastDayOfCurrentMonth() {
      const today = new Date();
      const firstDay = new Date(today?.getFullYear(), today?.getMonth(), 1);
      const lastDay = new Date(today?.getFullYear(), today?.getMonth() + 1, 0);
      this.firstDay = this.formatCurrentDate(firstDay);
      this.lastDay = this.formatCurrentDate(lastDay);
    },
    formatCurrentDate(date) {
      const year = date?.getFullYear();
      const month = (date?.getMonth() + 1).toString().padStart(2, "0");
      const day = date?.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
  },
};
</script>
<style>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1.2rem !important;
  height: 48px !important;
}

.theme--light.v-data-table {
  background-color: #ffffff;
  /* font-size: larger; */
  color: rgb(0 0 0 / 39%);
}

.v-data-table__mobile-row {
  align-items: center !important;
  display: flex !important;
  justify-content: flex-start !important;
}
.v-picker.v-card.v-picker--date.theme--light {
  width: 100% !important;
}
.v-data-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: none;
}
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: none;
  border: 2px solid #eee;
}
.header-toggle label {
  font-size: 12px;
}
.header-toggle .v-application .primary--text {
  color: #3c5cac !important;
  caret-color: #3c5cac !important;
}
.export-btn {
  background: #3c5cac;
  color: #fff !important;
  border-radius: 11px;
  padding: 12px;
}
.theme--light.v-btn--active:hover::before,
.theme--light.v-btn--active::before,
.theme--light.v-btn:hover::before {
  opacity: 0.18;
  border-radius: 29px;
}
.wo-diff-style {
  font-weight: 500 !important;
}
.wo-diff-style .v-icon.v-icon {
  color: #1976d2;
  margin-bottom: 1px;
}
.toggle-report .v-input--selection-controls {
  position: relative;
  top: -10px;
}
.toggle-report .v-application .primary--text {
  color: #3c5cac !important;
  caret-color: #3c5cac !important;
}
.home-table .v-expansion-panel::before {
  box-shadow: none !important;
}
@keyframes slide-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 100%;
  }
}
.chart-container{
  max-width: 90vw;
}
.chart-container .apexcharts-canvas{
  width: 90vw !important;
}
.chart-container svg{
  width: 100vw!important;
}
</style>
