import Vue from 'vue'
import VueRouter from 'vue-router'
//import HomeView from '../views/HomeView.vue'

import store from "@/store";

//=============================

import home from '../views/Home/home.vue'
import Login from '../views/AuthLogin/Login.vue'
import Clocking from '../views/AuthLogin/Clocking.vue'
import ForgetPassword from '../views/AuthLogin/ForgetPassword.vue'
import UpdatePassword from '../views/AuthLogin/UpdatePassword.vue'
import checkMail from '../views/AuthLogin/CheckYourMail.vue'






import Profile from '../views/Profile/profile.vue'

//================== setting ========
import managerUser from '../views/Setting/managerUser.vue'
import LicenseKey from '../views/Setting/LicenseKey.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/Login',
    name: 'Login',
    component: Login,
    meta: {
      notRequiresAuth: true,
    },
  },
  {
    path: '/Clocking/:token',
    name: 'Clocking',
    component: Clocking,
    meta: {
      notRequiresAuth: true,
    },
  },
  {
    path: '/ForgetPassword',
    name: 'ForgetPassword',
    component: ForgetPassword,
    meta: {
      notRequiresAuth: true,
    },
  },
  {
    path: '/checkMail',
    name: 'checkMail',
    component: checkMail,
    meta: {
      notRequiresAuth: true,
    },
  },
  
  {
    path: '/UpdatePassword',
    name: 'UpdatePassword',  
    
    component: UpdatePassword,
    meta: {
      notRequiresAuth: true,
    },
  },
  
  {
    path: '/',
    name: 'home',
    component: home,
    meta: {
      roles: 'Admin'
    //  notRequiresAuth: true,
    },
  },

  //============ Profile ========
  {
    path: '/Profile',
    name: 'Profile',
    component: Profile,
    meta: {
      roles: 'Admin'
    }
  },
  //============ setting managerUser ========
  {
    path: '/managerUser',
    name: 'managerUser',
    component: managerUser,
    meta: {
      roles: 'Admin'
    }
  },

  {
    path: '/LicenseKey',
    name: 'LicenseKey',
    component: LicenseKey,
    meta: {
      roles: 'Admin'
    }
  },
  
  {
    path: '/NotFound',
    name: 'NotFound',
   
    component: () => import('../views/NotFoundPage.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

//============== guard ============

router.beforeEach((to, from, next) => {
  // store.dispatch("toggleShowPreloader");
  // AUTH GAURD
  
  if (to.meta.notRequiresAuth) {
   
    if ((store.getters["AuthModule/isLoggedIn"] && to.path == "/") ) {
      next({ path: "/" });
      return;
    }
    if (store.getters["AuthModule/isLoggedIn"] == false) {
      next();
      return;
    }
  } else {
    if (store.getters["AuthModule/isLoggedIn"]) {
      next();
      return;
    }
    next({ name: "Login" });
  }
   next();
});

export default router
