<template>
  <v-app>
    <v-app-bar v-if="isLoggining && this.$route.meta.notRequiresAuth != true" app color="#3C5CACFF">
      <div class="d-flex align-center">
        <router-link style="text-decoration: none;" class-active="active" to="/" exact>
          <v-img alt="Vuetify Logo"  contain transition="scale-transition" width="135"
          style="margin-top:0px; margin-left: 5%;" src="../src/assets/images/headerimg/headerIcon.png" />
                </router-link>
                <div class="d-flex align-center"
                v-if="$vuetify.breakpoint.mdAndUp"
                >
                  <div class="hover-styling-for-tab">
                    <v-btn to="/" text color="white" class="dropdown-item mr-2 menu-links">
              <p class="router-link-deco">
                <span class="ml-2">Attendance Report</span>
              </p>
              </v-btn>
                  </div>
        <div style="" v-for="(item, index) in UserName" :key="index" class="hover-styling-for-tab">

            <v-btn :to="item.to" text color="white" class="dropdown-item mr-2 menu-links " v-if="index!=0">
              <p class="router-link-deco ">
                <span class="ml-2">{{ item.title }}</span>
              </p>
            </v-btn>
          </div>
      </div>
      </div>
      <v-spacer></v-spacer>
      <!-- UserName -->
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="ml-1" text dark v-bind="attrs" v-on="on">
            <span style="color: white;" class="header-item">{{userInfo.user.userName}}</span>
            <i class="fas fa-caret-down ml-1"></i>
          </v-btn>
        </template>
        <v-list>
          <div style="" v-for="(item, index) in UserName" :key="index">
            <v-btn :to="item.to" text class="dropdown-item menu-links"
            v-if="($vuetify.breakpoint.mdAndUp && (index !== 1 && index !== 2)) || $vuetify.breakpoint.smAndDown">
              <p class="router-link-deco text-center">
                <span class="ml-2">{{ item.title }}</span>
              </p>
            </v-btn>
          </div>
          <div @click="logout" class="logout-btn">
                <v-btn text class="dropdown-item menu-links w-full text-center">
                  <p class="router-link-deco w-full">
                    <span class="ml-2 w-full">Logout</span>
                  </p>
                </v-btn>
              </div>
        </v-list>
      </v-menu>

      <!--    <v-btn href="https://github.com/vuetifyjs/vuetify/releases/latest" target="_blank" text>
        <span class="mr-2">UserName</span>
      </v-btn> -->
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import store from "@/store";
import { mapGetters } from "vuex";
import router from "./router";
export default {
  
  name: 'App',

  data: () => ({
    isLoggining: store.getters["AuthModule/isLoggedIn"],
    UserName: [
      {
        title: "Profile",
        icon: "far fa-list-alt",
        to: "/Profile",
      },

      {
        title: "Users",
        icon: "far fa-list-alt",
        to: "/managerUser",
      },
      {
        title: "License Key",
        icon: "far fa-list-alt",
        to: "/LicenseKey",
      },
     

      // {
      //   title: "Logout",
      //   icon: "far fa-list-alt",
      //   to: "/Logout",
      // },
    ],

  }),
  computed: {
    ...mapGetters({
      userInfo: "AuthModule/userData",
    }),
  },

  methods:{
    logout() {
     // debugger; // eslint-disable-line no-debugger
      this.$store.dispatch("AuthModule/logout");
      router.push("/Login")
    },
  },
  mounted(){
    //console.log(this.userInfo.user)
  }
};
</script>

<style >
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');
.v-application{
  font-family: 'Montserrat', sans-serif;
}
.v-application p {
  margin-bottom: 0px;
}

.theme--dark.v-text-field--solo>.v-input__control>.v-input__slot {
  background: white !important;
}
.v-btn{
  text-transform: capitalize;
}
.v-menu__content--fixed{
  min-width: 146px!important;
    top: 63px!important;
}
.v-sheet.v-list a{
  text-align: center;
  width:100%;
}
.logout-btn{
  width:100%;
  text-align: center;
}
.logout-btn .v-btn{
width: 100%;
}
.hover-styling-for-tab a{
  border-radius:30px !important;
}
</style>
