import http from '../http'

export default {
    login(data) {
       // debugger; // eslint-disable-line no-debugger
       // console.log(data, "data login")
        return http.post('Authentication/login', {
            "password": data.password,
            "email": data.email,

        }).then((res) => {
            return res
        })
    },
}